import React, { useState } from "react";
import { Trans, withTranslation, useTranslation } from "react-i18next";
import TeammateSlide from "./teammate_slide";

import photo_bbenet from "../images/devops_a1_cloud_baptiste_b.jpg";
import photo_maadelise from "../images/devops_a1_cloud_marcantoine_a.jpg";
import photo_cgeninet from "../images/devops_a1_cloud_christophe_g.jpg";
import photo_sjeoffroi from "../images/devops_a1_cloud_simon_j.jpg";
import photo_cphu from "../images/devops_a1_cloud_christian_p.jpg";

export default function TeamSlider() {
  const { t } = useTranslation(["main"]);
  const LocalizedTeammateSlide = withTranslation("main")(TeammateSlide);
  const numSlides = 5;
  const rndInt = Math.floor(Math.random() * numSlides) + 1;

  // Define the active slide state
  const [activeSlide, setActiveSlide] = useState(`nav_item_${rndInt}`);

  // Function to stop the auto-rotation of the slider
  const stop_nous_auto_rorate = function () {
    clearTimeout(window.teamSliderInter);
  };

  // Function to handle clicks on the slides
  const handleSlideClick = (e, slideId) => {
    stop_nous_auto_rorate(e); // Stop auto-rotation
    setActiveSlide(slideId); // Set the clicked slide as active
  };

  return (
    <>
      <input type="hidden" name="rindex" id="rindex" value="1"></input>
      <div className="notre-equipe inner__page">
        <div className="container">
          <div className="row">
            <div className="col-12 av_block01">
              <h1 className="sec-title">
                {t("main:team_widget_section_title_txt")}
              </h1>
            </div>
            <div className="col-lg-6 col-12 av_block02">
              <div className="notre-equipe-block-img">
                <div className="all-img-client">
                  <ul className="nav nav-tabs" role="tablist">
                    <li
                      id="nav_item_1"
                      className={`nav-item epoch_block ${
                        activeSlide === "nav_item_1" ? "active" : ""
                      }`}
                      onClick={(e) => handleSlideClick(e, "nav_item_1")}
                    >
                      <a className="nav-link" data-toggle="pill" href="#bb">
                        <img
                          src={photo_bbenet}
                          alt={t("main:team_widget_thumb-alt_text", {
                            name: "Baptiste Benet",
                          })}
                        />
                      </a>
                    </li>
                    <li
                      id="nav_item_2"
                      className={`nav-item epoch_block ${
                        activeSlide === "nav_item_2" ? "active" : ""
                      }`}
                      onClick={(e) => handleSlideClick(e, "nav_item_2")}
                    >
                      <a className="nav-link" data-toggle="pill" href="#maa">
                        <img
                          src={photo_maadelise}
                          alt={t("main:team_widget_thumb-alt_text", {
                            name: "Marc-Antoine Adélise",
                          })}
                        />
                      </a>
                    </li>
                    <li
                      id="nav_item_3"
                      className={`nav-item epoch_block ${
                        activeSlide === "nav_item_3" ? "active" : ""
                      }`}
                      onClick={(e) => handleSlideClick(e, "nav_item_3")}
                    >
                      <a className="nav-link" data-toggle="pill" href="#cge">
                        <img
                          src={photo_cgeninet}
                          alt={t("main:team_widget_thumb-alt_text", {
                            name: "Christophe Géninet",
                          })}
                        />
                      </a>
                    </li>
                    <li
                      id="nav_item_4"
                      className={`nav-item epoch_block ${
                        activeSlide === "nav_item_4" ? "active" : ""
                      }`}
                      onClick={(e) => handleSlideClick(e, "nav_item_4")}
                    >
                      <a className="nav-link" data-toggle="pill" href="#sje">
                        <img
                          src={photo_sjeoffroi}
                          alt={t("main:team_widget_thumb-alt_text", {
                            name: "Simon Jeoffroi",
                          })}
                        />
                      </a>
                    </li>
                    <li
                      id="nav_item_5"
                      className={`nav-item epoch_block ${
                        activeSlide === "nav_item_5" ? "active" : ""
                      }`}
                      onClick={(e) => handleSlideClick(e, "nav_item_5")}
                    >
                      <a className="nav-link" data-toggle="pill" href="#cp">
                        <img
                          src={photo_cphu}
                          alt={t("main:team_widget_thumb-alt_text", {
                            name: "Christian Phu",
                          })}
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 av_block03">
              <div className="tab-content">
                <LocalizedTeammateSlide
                  id="bb"
                  activeClass={
                    activeSlide === "nav_item_1" ? "active show" : ""
                  }
                  name="Baptiste Benet"
                  occupation="co-founder / Cloud Architecture leader"
                  photo={photo_bbenet}
                  description={
                    <div>
                      <Trans i18nKey="main:team_widget_description_bbenet_html"></Trans>
                    </div>
                  }
                  socialLinks={{
                    linkedin: "https://www.linkedin.com/in/bbenet/",
                  }}
                  badges={[
                    {
                      type: "aws_sa_associate",
                      title: "AWS Solution Architect - Associate",
                      link: "https://www.credly.com/badges/34c0327f-170d-4c75-89d9-b962ab703544",
                    },
                    {
                      type: "aws_cloud_practic",
                      title: "AWS Certified Cloud Practitioner",
                      link: "https://www.credly.com/badges/7fd911c3-b8db-4ae7-b838-db06dc1aee0e",
                    },
                  ]}
                />

                <TeammateSlide
                  id="maa"
                  activeClass={
                    activeSlide === "nav_item_2" ? "active show" : ""
                  }
                  name="Marc-Antoine Adélise"
                  occupation="co-founder / cloud-native development & security leader"
                  photo={photo_maadelise}
                  description={
                    <div>
                      <Trans i18nKey="main:team_widget_description_maadelise_html"></Trans>
                    </div>
                  }
                  socialLinks={{
                    linkedin:
                      "https://www.linkedin.com/in/marc-antoine-ad%C3%A9lise-60ab342a/",
                  }}
                  badges={[
                    {
                      type: "aws_sec_specialty",
                      title: "AWS Security - Specialty",
                      link: "https://www.credly.com/badges/aeb86ff9-ab76-4c37-9a02-2601bd741c91",
                    },
                    {
                      type: "aws_cloud_practic",
                      title: "AWS Certified Cloud Practitioner",
                      link: "https://www.credly.com/badges/443a6f0e-a340-4c8d-827a-acd97fd4dfc1",
                    },
                    {
                      type: "mongo_dba_associate",
                      title: "MongoDB Associate Database Administrator",
                      link: "https://www.credly.com/earner/earned/badge/d4ad90c1-f98e-4663-b8fa-7af5e4539382",
                    },
                  ]}
                />

                <TeammateSlide
                  id="cge"
                  activeClass={
                    activeSlide === "nav_item_3" ? "active show" : ""
                  }
                  name="Christophe Géninet"
                  occupation="Cloud Infrastructure & Devops/SRE Leader"
                  photo={photo_cgeninet}
                  description={
                    <div>
                      <Trans i18nKey="main:team_widget_description_cgeninet_html"></Trans>
                    </div>
                  }
                  socialLinks={{
                    linkedin: "https://www.linkedin.com/in/christophe-geninet/",
                  }}
                  badges={[
                    {
                      type: "aws_sa_associate",
                      title: "AWS Solution Architect - Associate",
                      link: "https://www.credly.com/badges/c92e7851-f2b4-4f6f-8c13-5d1924e4b559",
                    },
                  ]}
                />

                <TeammateSlide
                  id="sje"
                  activeClass={
                    activeSlide === "nav_item_4" ? "active show" : ""
                  }
                  name="Simon J."
                  occupation="DevOps SRE Engineer"
                  photo={photo_sjeoffroi}
                  description={
                    <div>
                      <Trans i18nKey="main:team_widget_description_sjeoffroi_html"></Trans>
                    </div>
                  }
                  socialLinks={{
                    linkedin: "https://www.linkedin.com/in/sjffr/",
                  }}
                  badges={[
                    {
                      type: "aws_sa_associate",
                      title: "AWS Solution Architect - Associate",
                      link: "https://www.credly.com/badges/fed0fba4-10f5-4778-827d-cea90167512c",
                    },
                    {
                      type: "aws_cloud_practic",
                      title: "AWS Certified Cloud Practitioner",
                      link: "https://www.credly.com/badges/24b3bc34-4fdc-4659-a9b3-95e245a1e2ec",
                    },
                  ]}
                />

                <TeammateSlide
                  id="cp"
                  activeClass={
                    activeSlide === "nav_item_5" ? "active show" : ""
                  }
                  name="Christian Phu"
                  occupation="DevOps SRE Engineer"
                  photo={photo_cphu}
                  description={
                    <div>
                      <Trans i18nKey="main:team_widget_description_cphu_html"></Trans>
                    </div>
                  }
                  socialLinks={{
                    linkedin:
                      "https://www.linkedin.com/in/christian-phu-637481115/",
                  }}
                  badges={[
                    {
                      type: "aws_sysops_admin",
                      title: "Certified SysOps Administrator – Associate",
                      link: "https://www.credly.com/badges/7e5c4ee8-d996-4e3e-9171-8c682fdeef81",
                    },
                    {
                      type: "aws_cloud_practic",
                      title: "AWS Certified Cloud Practitioner",
                      link: "https://www.credly.com/badges/98cc65de-25e2-4e9d-894d-7761f202f28f",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
