import React from "react";

import { useTranslation } from "react-i18next";

import img_badge_sa_asso from "../images/badge_aws_sa_associate.png";
import img_badge_sec_spe from "../images/badge_aws_security_specialty.png";
import img_badge_cloud_practic from "../images/badge_aws_cloud_practitioner.png";
import img_badge_sysops_admin from "../images/badge_aws_sysops_admin.png";
import img_badge_mongodb_admin_associate from "../images/badge_mongodb_dba_associate.png";

export default function TeammateSlide({
  id,
  photo,
  name,
  description,
  occupation,
  socialLinks,
  activeClass,
  badges,
}) {
  const { t } = useTranslation(["main"]);
  const badgesImages = {
    aws_sa_associate: img_badge_sa_asso,
    aws_sec_specialty: img_badge_sec_spe,
    aws_cloud_practic: img_badge_cloud_practic,
    aws_sysops_admin: img_badge_sysops_admin,
    mongo_dba_associate: img_badge_mongodb_admin_associate,
  };
  return (
    <>
      <div
        className={`banner-section-main-content tab-pane fade ${activeClass}`}
        id={id}
        role="tabpanel"
        aria-labelledby={id}
      >
        <div className="notre-equipe-secound-section">
          <img
            src={photo}
            alt={t("main:team_widget_photo-alt_text", { name: name })}
          />
        </div>
        <div className="notre-equipe-secound-section-content">
          <h2>{name}</h2>
          <span>{occupation}</span>
          {description}

          <ul className="social_share">
            {socialLinks.linkedin ? (
              <li>
                <a
                  href={socialLinks.linkedin}
                  title={t("main:team_widget_social-title_text", {
                    name: name,
                    network: "LinkedIn",
                  })}
                >
                  <i className="fa fa-linkedin-square" aria-hidden="true" />
                </a>
              </li>
            ) : (
              ""
            )}

            {socialLinks.git ? (
              <li>
                <a
                  href={socialLinks.git}
                  title={t("main:team_widget_social-title_text", {
                    name: name,
                    network: "GitHub",
                  })}
                >
                  <i className="fa fa-git-square" aria-hidden="true" />
                </a>
              </li>
            ) : (
              ""
            )}

            {socialLinks.insta ? (
              <li>
                <a
                  href={socialLinks.insta}
                  title={t("main:team_widget_social-title_text", {
                    name: name,
                    network: "Instagram",
                  })}
                >
                  <i className="fa fa-instagram" aria-hidden="true" />
                </a>
              </li>
            ) : (
              ""
            )}

            {socialLinks.twitter ? (
              <li>
                <a
                  href={socialLinks.twitter}
                  title={t("main:team_widget_social-title_text", {
                    name: name,
                    network: "Twitter",
                  })}
                >
                  <i className="fa fa-twitter" aria-hidden="true" />
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>

          <ul className="badges">
            {badges.map((badge, index) => (
              <li key={index}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={badge.link}
                  title={t("main:team_widget_badge-title_text", {
                    name: name,
                    badgetitle: badge.title,
                  })}
                >
                  <img
                    src={badgesImages[badge.type]}
                    alt={t("main:team_widget_badge-alt_text", {
                      name: name,
                      badgetitle: badge.title,
                    })}
                  />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
